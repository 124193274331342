/* UniqueBanner.css */
.unique-banner-container {
    background-color: #ffecb3;
    padding: 1rem;
    text-align: center;
    margin-top: 7rem;
    /* Default desktop margin */
}

@media (max-width: 768px) {
    .unique-banner-container {
        margin-top: 4rem;
        /* Mobile margin */
    }
}

.unique-banner-heading {
    margin: 0;
}